import React from 'react';
import './App.css';
import TextLoop from "react-text-loop";
import ScrollAnimation from 'react-animate-on-scroll';
import ReactAudioPlayer from 'react-audio-player';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <div className="Main-header">    
      <img src="./EustonLogo.svg"></img>
      <img src="./Menu.svg"></img>
     
      </div>
      <div>
        <ScrollAnimation animateIn="fadeIn" delay="500" >
          <h1>Creating Tommorow.</h1>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn" delay="1500" >
          <h1>Yesterday.</h1>
        </ScrollAnimation>
        </div>
        <div>
          <h4> 
          <TextLoop interval="4000" >
          <div>
          <h4><span>Mergers & Acquisitions</span></h4>
          <h4> - </h4>
          </div>
          <div>
          <h4><span>Corporate Tax Liability Liquidation Strategies</span></h4>
          <h4> - </h4>
          </div>  
          <div>
          <h4><span>Merging Corporate Businesses and Acquisition Mergers</span></h4>
          <h4> - </h4>
          </div>
          <div>
          <h4><span>Corporate Consultation and Governance</span></h4>
          <h4> - </h4>
          </div>
          <div>
          <h4><span>Hostile Takovers and Business Acquisition Mergers</span></h4>
          <h4> - </h4>
          </div>
          <div>
          <h4><span>Political and Social Engineering Consultation</span></h4>
          <h4> - </h4>
          </div>           
          </TextLoop>
        </h4>
        </div> 

      </header>
      <div className="Container">
      <div className="Container-inner-left">
      <ScrollAnimation animateIn="fadeIn">
        <h3>
        Euston & Partners is a worldwide global international conglomerate  <span> – We work with our clients to align verticals across the top and bottom line, reducing overheads and utilising leveraged frameworks. </span>
        </h3>
      </ScrollAnimation>
      </div>
      <div className="Container-inner-right">
        <img src="./Stock.jpg"></img>
      </div>
    </div>

      <div className="Container">
        <div className="Container-inner-left">
          <ScrollAnimation animateIn="fadeIn">
            <h3>
              <span>We work at the speed of business. </span> 
               Continually delivering, through embeded innovative cultural change strategies.        
            </h3>
          </ScrollAnimation>
        </div>
        <div className="Container-inner-right">
        <img src="./Speedof.jpg"></img>
        </div>
      </div>

      <div className="Container">
        <div className="Container-inner-left">
          <ScrollAnimation animateIn="fadeIn">
              <h3>
                We turn ideas into reality <span>– by converging cultural experience, brand experience, political contacts and social engineering.</span>
              </h3>
            </ScrollAnimation>
          </div>
          <div className="Container-inner-right">
            <img src="./Flag.jpg"></img>
          </div>
      </div>

      <div className="Container">
        <div className="Container-inner-full">
          <ScrollAnimation animateIn="fadeIn">
          <h3>
          We provide analysis strategic to all our clients beyond and above and to the future <span>– Clients <sup style={{fontSize: "2px"}}>we would like to work with</sup>include.</span>     
           </h3>
          <div className="Client-block">
            <ScrollAnimation animateIn="fadeIn" delay="400" >
              <div className="Client-image" >
                <img src="./client1.svg"></img>
              </div>
            </ScrollAnimation>                   
            <ScrollAnimation animateIn="fadeIn" delay="800" >
            <div className="Client-image" >
                  <img  src="./client2.svg"></img>
                </div>
            </ScrollAnimation>   
            <ScrollAnimation animateIn="fadeIn" delay="1200" >
            <div className="Client-image" >
              <img src="./client3.svg"></img>
            </div>
          </ScrollAnimation> 
          <ScrollAnimation animateIn="fadeIn" delay="1600" >
          <div className="Client-image" >
            <img src="./client4.svg"></img>
          </div>
        </ScrollAnimation> 
        <ScrollAnimation animateIn="fadeIn" delay="2000" >
        <div className="Client-image" >
          <img src="./client8.svg"></img>
        </div>
      </ScrollAnimation> 
        <ScrollAnimation animateIn="fadeIn" delay="2400" >
            <div className="Client-image" >
              <img src="./client6.svg"></img>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay="2800" >
          <div className="Client-image" >
            <img src="./client7.svg"></img>
          </div>
        </ScrollAnimation>   
        <ScrollAnimation animateIn="fadeIn" delay="3200" >
        <div className="Client-image" >
          <img src="./client5.svg"></img>
        </div>
      </ScrollAnimation>    
      <ScrollAnimation animateIn="fadeIn" delay="3600" >
        <div className="Client-image" >
          <img src="./client9.svg"></img>
        </div>
      </ScrollAnimation> 
      <ScrollAnimation animateIn="fadeIn" delay="4000" >
      <div className="Client-image" >
        <img src="./client10.svg"></img>
      </div>
    </ScrollAnimation>  
    <ScrollAnimation animateIn="fadeIn" delay="4400" >
    <div className="Client-image" >
      <img src="./client11.svg"></img>
    </div>
  </ScrollAnimation> 
  <ScrollAnimation animateIn="fadeIn" delay="4800" >
  <div className="Client-image" >
    <img src="./client12.svg"></img>
  </div>
</ScrollAnimation> 
<ScrollAnimation animateIn="fadeIn" delay="4800" >
<div className="Client-image" >
  <img src="./client13.svg"></img>
</div>
</ScrollAnimation> 

          </div>
          </ScrollAnimation>      
        </div>
      </div>

    <div className="Container">
      <div className="Container-inner-center">
      <ScrollAnimation animateIn="fadeIn">

        <h3><span>
        Euston & Partners operates out of
        </span>
        </h3>
        <h3>
        Shanghai · Hong Kong · New York · Los Angeles · London · Verdansk · London(Canada) · Mumbai · Lagos · Paris · Tokyo · Rio · Seoul · Karachi · Cairo · Istanbul · Toronto · Madrid · Kabul · Berlin · Rome · Busan
        </h3>
        </ScrollAnimation>

      </div>
    </div>
 
    <ReactAudioPlayer
      src="./mixaund-ready-to-go.mp3"
      autoPlay={true}
      controls={false}
    />
  </div>
  );
}

export default App;
